import React, { Component, useState } from "react";
import WheelComponent from './WheelComponent'

const App = () => {
   const [points, setPoints] = useState(false);
  const segments = [
    "wear L Plug for 30 minutes",
    "wear L Plug for 4 hours",
    "wear L/XL Plug for 30 minutes",
    "wear L/XL Plug for 2 hours",
    "wear XL Plug for 30 minutes",
    "wear XL Plug for 1 hour",
    "wear XXL Plug for 5 minutes",
    "wear XXL Plug for 30 minutes",
    "Play Beat Saber for 30 minutes",
    "Play Beat Saber for 2 hours",
    "Drink an average of 2 oz per hour",
    "Drink an average of 4 oz per hour",
    "Drink an average of 6 oz per hour",
    "Drink an average of 8 oz per hour",
    "Drink an average of 10 oz per hour",
    "Drink an average of 12 oz per hour",
    "Drink an average of 14 oz per hour",
    "Drink an average of 16 oz per hour",
    "Drink an average of 18 oz per hour",
    "Drink an average of 20 oz per hour",
    "Drink an average of 22 oz per hour",
    "Wear Bandaids for 1 hour",
    "Wear Bandaids for 2 hours",
    "Wear Bandaids for 4 hours",
    "Wear Bandaids for 8 hours",
    "Wear Bandaids for 12 hours",
    "Wear Bandaids for 24 hours",
    "Browse porn/faproulette and tease self for 10 minutes",
    "Browse porn/faproulette and tease self for 30 minutes",
    "Browse porn/faproulette and tease self for 1 hour",
    "Don't wear jeans for 24 hours or add 1 week to chastity time",
    "Diaper waistband must be visible for 24 hours or add 5 days to chastity time",
    "Don't wear any non-skimpy clothes for 24 hours or add 72 hours to chastity time",
    "Don't wear any non-seethru clothes for 24 hours or add 48 hours to chastity time",
    "Don't wear anything but a diaper for 24 hours or add 24 hours to chastity time",
    "Spend 5 minutes outside in just a diaper",
    "Spend 10 minutes outside in just a diaper",
    "Spend 30 minutes outside in just a diaper",
    "Toilet completely off limits for 24 hours",
    "Fill diaper with oatmeal from 1 cup of oats, don't change for 1 hour",
    "Fill diaper with oatmeal from 1.5 cups of oats, don't change for 1 hour",
    "Fill diaper with oatmeal from 2 cups of oats, don't change for 1 hour",
    "Fill diaper with oatmeal from 1 cup of oats, don't change for 2 hours",
    "Fill diaper with oatmeal from 1.5 cups of oats, don't change for 2 hours",
    "Fill diaper with oatmeal from 2 cups of oats, don't change for 2 hours",
    "Fill diaper with oatmeal from 1 cup of oats, don't change for 4 hours",
    "Fill diaper with oatmeal from 1.5 cups of oats, don't change for 4 hours",
    "Fill diaper with oatmeal from 2 cups of oats, don't change for 4 hours",
    "Maximum of 2 diaper changes allowed today",
    "Maximum of 1 diaper change allowed today",
    "Double diapers required during the day",
    "Fill butt with oatmeal from 1 cup of oats, don't change for 1 hour",
    "Fill butt with oatmeal from 1 cup of oats, don't change for 2 hours",
    "Fill butt with oatmeal from 1 cup of oats, don't change for 4 hours",
    "Throw away 1 pair of normal underwear",
    "Printed diaper required during the day",
    "Printed diaper required during the day. Must be completely visible or add 24 hours to chastity time",
    "Wear L plug to sleep tonight",
    "Wear MegaMax diaper to sleep tonight",
    "Wear collar during the day today",
    "Wear collar and ankle cuffs during the day today",
    "Wear collar for 24 hours",
    "Wear collar and cuffs during the day today (wrist cuffs work exclusion)",
    "Wear collar, cuffs, and harness during the day today (wrist cuffs work exclusion)",
    "Wear collar and ankle cuffs for 24 hours",
    "Wear collar, cuffs, and harness for 24 hours (wrist cuffs work and sleep exclusion)",
    "Wear collar, cuffs, stockings, and harness during the day today (wrist cuffs work exclusion)",
    "Wear collar, cuffs, stockings, and harness for 24 hours (wrist cuffs work and sleep exclusion)",
    "Wear collar, cuffs, and stockings during the day today (wrist cuffs work exclusion)",
    "Wear collar, cuffs, and stockings for 24 hours (wrist cuffs work and sleep exclusion)",
    "Clean one horizontal surface in your room completely",
    "Clean one horizontal surface in the house completely",
    "Vacuum the stairs",
    "Vacuum bedroom",
    "Do one load of laundry",
    "Massage prostate for 10 minutes",
    "Massage prostate for 20 minutes",
    "Wear Lovense Prostate toy for 30 minutes",
    "Wear Lovense Prostate toy for 2 hours",
    "Wear Lovense Plug for 30 minutes",
    "Wear Lovense Plug for 2 hours",
    "Wear Tunnel Plug for 10 minutes",
    "Wear Tunnel Plug for 30 minutes"
  ];
  const segColors = [
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000"
  ];
  const onFinished = (winner) => {
    alert(winner);
  };

     const ponitschek = () => {
       if (!points) {
         alert("get more points");
       } else {
       }
     };

  return (
    <div className="wheel-box">
      <WheelComponent
        segments={segments}
        segColors={segColors}
        winningSegment={segments[100]}
        onFinished={(winner) => onFinished(winner)}
        primaryColor='black'
        contrastColor='white'
        buttonText='Spin'
        isOnlyOnce={false}
        size={600}
        upDuration = {25}
        downDuration = {50}
      />
    </div>
  );
};

export default App;
